import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./fonts.css";
import "./index.css";
import "./images/icons/Audio-describe-con-PC.png";
import "./images/icons/Captioned-icon-PC.png";
import "./images/icons/Relaxed-Performance-icon-1.png";
import "./images/icons/SD-FullColour-cmyk2.svg";
import "./images/icons/Signed-icon-PC.png";
import App from "./App";
import store from "./redux/store/store";
import * as actions from "./redux/actions/index";

ReactDOM.render(<App />, document.getElementById("root"));

store.dispatch(actions.init());
