import React, {
  useRef,
  RefObject,
  Dispatch,
  CSSProperties,
  useState,
  useEffect,
} from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { StoreState } from "../redux/store/types";
import * as selectors from "../redux/selectors";
import { SQLDate } from "@soltshared/shared-domain/util";
import { Show } from "../domain/board-ticketing";
import { useHotkeys } from "react-hotkeys-hook";
import classNames from "classnames";
import { boardUIInit } from "../redux/actions";
import { BoardUIInit } from "../redux/actions/types";
import { useWindowSize } from "react-hooks-window-size";
import { DateTime } from "luxon";

const windowWidth = window.innerWidth;

function sortTitleForShowTitle(title: string): string {
  const replacements = [/^the /i, /^les /i, /^la /i, /^disney's the /i];

  return _.reduce(
    replacements,
    (acc, regex) => {
      return acc.replace(regex, "");
    },
    title
  );
}

const ShowUI = connect(
  (
    state: StoreState,
    ownProps: {
      show: Show;
      date: SQLDate;
    }
  ) => {
    return {};
  }
)((props: { show: Show; index: number }) => {
  const { show } = props;
  const showRef = useRef<HTMLDivElement>(null);

  let performancesWithPrices = _.orderBy(show.performancesToShow, "starts");

  if (_.isEmpty(performancesWithPrices)) {
    return null;
  }

  const isMultiPart = !_.isEmpty((show.performancesToShow || []).filter(perf => {
    return perf.part_number;
  }));

  const now = DateTime.local();
  const daySeatsOnSaleTime = now.startOf("day").plus({hours: 10});
  const shouldDisplayDaySeats = now > daySeatsOnSaleTime && show.hasDaySeats;

  return (
    <article
      className={`white bg-white-10 br4 relative overflow-hidden`}
      style={{marginBottom: "0.75rem"}}
      ref={showRef}
    >
      <h3
        className={`f2:5 fw8 lh-title w-100 br4 br--top ph3 pb1 pt2 absolute top-0 left-0  ${
          show.showOfTheWeek
            ? "bg-tkts-red bl br bw3 b--transparent"
            : "bg-transparent bw3"
        }`}
      >
        <span className="w-30 w-22:5-xl h1 dn dib-xl mr4"></span>
        <span className="ml2 w-80 w-60-xl dib">
          {show.showOfTheWeek
            ? `Show Of The Week: ${show.show_name}`
            : show.show_name}
        </span>
      </h3>
      <div
        className={`flex flex-row justify-between items-end z-1 br4 relative mt4 mt0-xl pa3 ${
          show.showOfTheWeek ? "bl br bb bw2 b--tkts-red" : "b--transparent bw2 bb"
        }`}
      >
        <div className="w-30 w-22:5-xl">
          <div className="aspect-ratio--2x1 relative">
            { show.image && (
              <img
                src={show.image.replace("http://", "https://")}
                alt={show.show_name}
                className="w-100 h-auto br4 absolute top-0 left-0 "
              />
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between relative ml4 flex-grow-1 overflow-hidden">
          <div className="flex flex-row pt5">
            {_.map(performancesWithPrices, (performance, i: number) => {
              let accessKeys = _.chain(performance.accessPerformances)
                .flatMap((access) => access.performance_type)
                .uniq()
                .value();
              
                let discountAvailable = 0;
                if (show.provider === 'lineup' && performance.source === 'tkts-booth') {
                  const groupedPrices = _.groupBy(performance.prices, 'priceband');
                  _.map(groupedPrices, (prices) => {
                    const tktsPrice = _.find(prices, (price) => price.location.includes('TKTS'));
                    const standardPrice = _.find(prices, (price) => !price.location.includes('TKTS'));
  
                    if (tktsPrice) {
                      if (standardPrice) {
                        const saving = Math.floor(((standardPrice.ticketprice - tktsPrice.ticketprice) / standardPrice.ticketprice) * 100);
                        discountAvailable = saving > 0 ? saving : 0;
                      }
                    }
                  })
                } else {
                  discountAvailable = _.chain(performance.prices)
                  .filter((price) => price.onsale && price.fullprice)
                  .map((price: any) => {
                    return Math.floor(
                      ((price.fullprice! - price.ticketprice) /
                        price.fullprice!) *
                        100
                    );
                  })
                  .max()
                  .value();
                }


              let priceString: string | undefined;
              if (typeof performance.price_from  === "number" && typeof performance.price_to ==="number") {
                priceString = performance.price_from === performance.price_to
                    ? `£${performance.price_from.toFixed(2)}`
                    : `£${performance.price_from.toFixed(
                        2
                      )} - £${performance.price_to.toFixed(2)}`;
              }

              let partLabel: undefined | string;
              if (performance.part_number) {
                partLabel = `Part ${performance.part_number === 1 ? 'One' : 'Two'}`;
              }

              return (
                <div className="flex relative w-100 align-stretch">
                  <div
                    className="tc ph2 flex flex-column"
                    style={{ minWidth: "275px" }}
                    key={i + performance.starts}
                  >
                    <div
                      className={`br4 br--top ba pv1 fw7 f3:5 w-100 flex items-center justify-center ${
                        discountAvailable
                          ? "bg-tkts-red white b--tkts-red"
                          : "bg-white black-70 b--white"
                      }`}
                    >
                      <span>
                        { partLabel && <span>{ partLabel } </span>}
                        {DateTime.fromSQL(performance.starts).toFormat("h:mma")}
                      </span>

                      {_.includes(accessKeys, "audio") && (
                        <img
                          className="w2 h2 ml2"
                          src="static/media/Audio-describe-con-PC.png"
                          alt="Audio Described Performance"
                          width="40"
                          height="40"
                        />
                      )}
                      {_.includes(accessKeys, "captioned") && (
                        <img
                          className="w2 h2 ml2"
                          src="static/media/Caption-icon-PC.png"
                          alt="Captioned Performance"
                          width="40"
                          height="40"
                        />
                      )}
                      {_.includes(accessKeys, "signed") && (
                        <img
                          className="w2 h2 ml2"
                          src="static/media/Sign-icon-PC.png"
                          alt="Sign Language Interpreted"
                          width="40"
                          height="40"
                        />
                      )}
                      {_.includes(accessKeys, "relaxed") && (
                        <img
                          className="w2 h2 ml2"
                          src="static/media/Relaxed-Performance-icon-1.png"
                          alt="Relaxed Performance"
                          width="40"
                          height="40"
                        />
                      )}
                    </div>
                    <div
                      className={`ph3 pv2 fw8 f3:5 bb bl br br4 br--bottom h-100 w-100 flex flex-column items-center justify-center shadow-4 bg-white-10 ${
                        discountAvailable ? "b--tkts-red" : "b--white"
                      }`}
                      style={{ minHeight: "99px" }}
                    >
                      <div>{priceString}</div>
                      {discountAvailable && (
                        <div className="bg-tkts-red pv1 ph2 fw7 f4 mt2">
                          Up to {discountAvailable}% off
                        </div>
                      )}
                    </div>
                  </div>  
                </div>
              );
            })}
            {
              isMultiPart && (
                <div
                  className="ml2 self-end br4 pa3 flex flex-column bg-white-10 justify-end"
                  style={{ width: "275px" }}>
                  <span className="ttu f4 mb2">* Please note</span>
                  <span className="f5">You must purchase tickets for both parts of this show</span>
                </div>
              ) 
            }
            {shouldDisplayDaySeats && 
              <div
                className="tc ml2 self-end br4 pa3 flex flex-column justify-center items-center b--olt-orange ba bw1 br3 absolute right-0 top-0 bottom-0"
                style={{width: "275px"}}
                >
                <p style={{ color: "#F48123", fontSize: '1.8rem' }}>{show.daySeatPriceString} Day Seats available!</p>
                <p className="mt3 white o-80" style={{ fontSize: '1.3rem' }}>Ask at window for more details</p>
              </div>
            }   
          </div>
        </div>
      </div>
    </article>
  );
});

const createSpacers = (
  params: {
    viewport: RefObject<HTMLDivElement>;
    showsList: RefObject<HTMLDivElement>;
  },
  setPages: any,
  initiateBoard: any
) => {
  const calculatePages = (
    viewport: RefObject<HTMLDivElement>,
    showsList: RefObject<HTMLDivElement>
  ) => {
    if (viewport.current && showsList.current) {
      const viewportHeight = viewport.current.offsetHeight;
      const showsElements = showsList.current.children;
      const pages = [
        {
          spacerAfterShowUrl: null,
          spacerHeight: 0,
          pageHeight: 0,
          offsetTop: 0,
        },
      ] as any[];

      const els = Array.prototype.slice.call(showsElements) as HTMLDivElement[];

      for (let i = 0; i < els.length; i++) {
        const el = els[i];
        const currentPage = _.last(pages);

        if (currentPage.pageHeight + el.offsetHeight >= viewportHeight) {
          const prevEl = els[i - 1];
          if (!prevEl) {
            continue;
          }

          // split across the end of a page, so finish up the
          // current spacer and start a new one
          currentPage.spacerHeight = viewportHeight - currentPage.pageHeight;
          currentPage.spacerAfterShowUrl = prevEl.dataset.showUrl;

          const spacerHeights = _.reduce(
            pages,
            (acc, page) => {
              return acc + page.spacerHeight;
            },
            0
          );

          pages.push({
            spacerAfterShowUrl: null,
            spacerHeight: 0,
            pageHeight: el.offsetHeight,
            offsetTop: spacerHeights + el.offsetTop,
          });
        } else {
          currentPage.pageHeight += el.offsetHeight;
        }
      }
      return pages;
    } else {
      return [];
    }
  };

  const pages = calculatePages(params.viewport, params.showsList);

  initiateBoard({
    page: {
      pageCount: pages.length,
      pageNumber: 0,
    },
    initiated: true,
  });

  setPages(pages);
};

const ShowsList = (params: {
  pageState: any;
  viewportRef: RefObject<HTMLDivElement>;
  showsRef: RefObject<HTMLDivElement>;
  shows: Show[];
  date: any;
  boardState: StoreState["boardUI"];
  initiateBoard: Dispatch<BoardUIInit>;
}) => {
  const [pages, setPages] = useState({} as any);
  const {
    pageState,
    viewportRef,
    showsRef,
    date,
    shows,
    boardState,
    initiateBoard,
  } = params;

  const currentPage = _.get(pages, [pageState.pageNumber], null);

  const sortedShows = _.chain(shows)
    .sortBy((show) => {
      return sortTitleForShowTitle(show.show_name);
    })
    .unionBy("url")
    .value();

  useEffect(() => {
    console.log("createSpacers");
    createSpacers(
      {
        viewport: viewportRef,
        showsList: showsRef,
      },
      setPages,
      initiateBoard
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardState.now, boardState.page.pageNumber === pages.length]);

  return (
    <div className={`flex flex-column w-100 ph4 mt3`}>
      <div
        className="flex-auto shows-container relative overflow-y-hidden "
        ref={viewportRef}
      >
        <div
          className="shows-wrapper"
          style={{
            transition: "transform ease 1.25s",
            transform: `translateY(-${
              currentPage ? currentPage.offsetTop : 0
            }px`,
          }}
          ref={showsRef}
        >
          {_.map(sortedShows, (show, i) => {
            if (i === 0) {
            }
            const spacer = _.find(pages, (page) => {
              return page.spacerAfterShowUrl === show.url;
            });

            return (
              <div
                key={show.show_url}
                className={classNames("show-item overflow-hidden ")}
                data-show-url={show.url}
              >
                <ShowUI show={show} date={date} index={i} />
                {spacer && boardState.renderSpacers && (
                  <div
                    className="spacer"
                    style={{ height: spacer.spacerHeight }}
                  />
                )}
              </div>
            );
          })}
          {DateTime.local().toISO() < "2021-09-14" && (
            <img
              src="https://res.cloudinary.com/solt/image/upload/v1628590338/TKTSBooth/TKTS_-_Only_Available_online.png"
              alt="Only available online"
              className="w-100 vh-100 "
            />
          )}
        </div>
      </div>
      <div
        className={classNames(
          "flex flex-row items-center justify-between w-100 white"
        )}
        style={{ minHeight: "38px", marginBottom: "12px" }}
      >
        <div className="sans-serif fw4 f4 lh-copy mv0 db flex items-center">
          <div>Preferred payment partner</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.11676in"
            height="1.5in"
            viewBox="0 0 152.407 108"
            className="ml2 w3 h2"
          >
            <g>
              <rect width="152.407" height="108" style={{ fill: "none" }} />
              <g>
                <rect
                  x="60.4117"
                  y="25.6968"
                  width="31.5"
                  height="56.6064"
                  style={{ fill: "#ff5f00" }}
                />
                <path
                  d="M382.20839,306a35.9375,35.9375,0,0,1,13.7499-28.3032,36,36,0,1,0,0,56.6064A35.938,35.938,0,0,1,382.20839,306Z"
                  transform="translate(-319.79649 -252)"
                  style={{ fill: "#eb001b" }}
                />
                <path
                  d="M454.20349,306a35.99867,35.99867,0,0,1-58.2452,28.3032,36.00518,36.00518,0,0,0,0-56.6064A35.99867,35.99867,0,0,1,454.20349,306Z"
                  transform="translate(-319.79649 -252)"
                  style={{ fill: "#f79e1b" }}
                />
                <path
                  d="M450.76889,328.3077v-1.1589h.4673v-.2361h-1.1901v.2361h.4675v1.1589Zm2.3105,0v-1.3973h-.3648l-.41959.9611-.41971-.9611h-.365v1.3973h.2576v-1.054l.3935.9087h.2671l.39351-.911v1.0563Z"
                  transform="translate(-319.79649 -252)"
                  style={{ fill: "#f79e1b" }}
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="lh-copy fw7 f4">
          Page {pageState.pageNumber + 1}/{pages.length}
        </div>
      </div>
    </div>
  );
};

export const BoardControlsUI = (props: { displayDate: SQLDate }) => {
  const { displayDate } = props;

  const [hidden, setHidden] = useState(true);
  useHotkeys("ctrl+y", () => {
    setHidden(!hidden);
  });

  if (hidden) {
    return null;
  }

  const navigateWithNewParams = (params: URLSearchParams) => {
    const { protocol, host, pathname } = window.location;
    const nextHref = `${protocol}//${host}${pathname}?${params.toString()}`;
    window.location.href = nextHref;
  };

  return (
    <div className="relative top-0 left-0 right-0">
      <div className="bg-light-gray pa3">
        <div className="flex flex-row items-center">
          <label>
            View board for date:
            <input
              className="ml2"
              type="date"
              required={true}
              value={displayDate}
              onChange={(e) => {
                const params = new URLSearchParams(window.location.search);
                params.set("date", e.target.value);
                navigateWithNewParams(params);
              }}
            />
          </label>
        </div>

        {/* <div className="mt3">
          <select
            defaultValue={useLegacyTKTSEndpoint ? "legacy" : "api"}
            onChange={e => {
              const params = new URLSearchParams(window.location.search);
              params.set(
                "legacytkts",
                e.target.value === "legacy" ? "true" : "false"
              );
              navigateWithNewParams(params);
            }}
          >
            <option value="legacy">Use data from TKTS uploader</option>
            <option value="api">Use data from TKTS API</option>
          </select>
        </div> */}
      </div>
    </div>
  );
};

export const BoardUI = connect(
  (state: StoreState) => {
    const displayDate = selectors.displayDate(state);
    const shows = selectors.showsForDateTime(state, displayDate);
    return {
      date: displayDate,
      shows: _.values(shows) as Show[],
      boardState: state.boardUI,
    };
  },
  (dispatch: Dispatch<BoardUIInit>, ownProps) => {
    return {
      initiateBoard: ({ page }: BoardUIInit) => {
        dispatch(boardUIInit(page, true));
      },
    };
  }
)(
  (props: {
    date: SQLDate;
    shows: Show[];
    initiateBoard: Dispatch<BoardUIInit>;
    boardState: any;
  }) => {
    const { date, shows, initiateBoard, boardState } = props;

    if (_.isEmpty(shows)) {
      return (
        <div className="bg-black flex flex-column vh-100 overflow-hidden">
          <BoardControlsUI displayDate={date} />
          <div className="h-100 flex flex-column items-center justify-center mw9 center tc fw7 f2 pa4 white-90 mt4 lh-copy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1182.35 271.41"
              className="h4 center mb4"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <rect y="5.7" width="283.45" height="259.83" fill="#d2222a" />
                  <path
                    d="M88.14,68.65a16.67,16.67,0,0,1-5,12.32,17,17,0,0,1-12.4,5,16.91,16.91,0,0,1-12.41-5,16.71,16.71,0,0,1-5-12.31,16.76,16.76,0,0,1,5-12.36,17,17,0,0,1,12.46-5,17,17,0,0,1,12.45,5A16.73,16.73,0,0,1,88.14,68.65Zm-3.71,0a13.45,13.45,0,0,0-4-9.84,13.21,13.21,0,0,0-9.79-4,13,13,0,0,0-10,4.06A13.81,13.81,0,0,0,57,68.65a13.48,13.48,0,0,0,3.93,9.84,14,14,0,0,0,19.59,0A13.41,13.41,0,0,0,84.43,68.65Z"
                    fill="#fff"
                  />
                  <path
                    d="M108.63,69.08H96.88V85.3H93.16V52h15.47v3.49H96.88V65.61h11.75Z"
                    fill="#fff"
                  />
                  <path
                    d="M129.75,69.08H118V85.3h-3.71V52h15.47v3.49H118V65.61h11.76Z"
                    fill="#fff"
                  />
                  <path d="M139.11,85.3H135.4V52h3.71Z" fill="#fff" />
                  <path
                    d="M172.39,83.15a17.42,17.42,0,0,1-4.94,2,22.14,22.14,0,0,1-5.65.82,17.38,17.38,0,0,1-12.68-4.91,16.66,16.66,0,0,1-5-12.45,16.5,16.5,0,0,1,5.12-12.31,17.42,17.42,0,0,1,12.63-5,22,22,0,0,1,5.89.77,14.24,14.24,0,0,1,4.66,2.14V58.4a16.77,16.77,0,0,0-10.09-3.58A14.47,14.47,0,0,0,152,58.76a13,13,0,0,0-4.17,9.85,13.39,13.39,0,0,0,4,9.91,13.55,13.55,0,0,0,9.91,4,18.22,18.22,0,0,0,5.8-1,15,15,0,0,0,4.88-2.62Z"
                    fill="#fff"
                  />
                  <path d="M181.32,85.3h-3.71V52h3.71Z" fill="#fff" />
                  <path
                    d="M215.27,85.3h-4.09l-4.34-10.07H193L188.72,85.3h-4.1L200,51.07Zm-9.79-13.24L200,59.2l-5.52,12.86Z"
                    fill="#fff"
                  />
                  <path d="M231.62,85.3H219V52h3.72V81.89h8.86Z" fill="#fff" />
                  <path d="M57.62,133.16h-18V99.85h9v25.67h9Z" fill="#fff" />
                  <path
                    d="M95,116.55a16.42,16.42,0,0,1-5.19,12.34,17.83,17.83,0,0,1-12.84,5,17.87,17.87,0,0,1-12.87-5,17.28,17.28,0,0,1,0-24.71,17.78,17.78,0,0,1,12.85-5,17.74,17.74,0,0,1,12.84,5A16.56,16.56,0,0,1,95,116.55Zm-9,0a9,9,0,1,0-18.07,0,8.63,8.63,0,0,0,2.55,6.38A8.85,8.85,0,0,0,77,125.48a8.72,8.72,0,0,0,9-8.93Z"
                    fill="#fff"
                  />
                  <path
                    d="M132.64,133.16H124l-15.42-20.67v20.67H99.89V99.85h8.72L124,120.56V99.85h8.65Z"
                    fill="#fff"
                  />
                  <path
                    d="M167.39,116.55q0,8.42-4.9,12.51t-15.19,4.1h-8.49V99.85H146q11.42,0,16.4,4.06T167.39,116.55Zm-9,0q0-4.81-2.47-7.09c-1.64-1.51-4.21-2.27-7.69-2.27h-.75v18.63h.75c3.5,0,6.06-.75,7.7-2.25S158.39,119.73,158.39,116.55Z"
                    fill="#fff"
                  />
                  <path
                    d="M206.58,116.55a16.42,16.42,0,0,1-5.19,12.34,19.08,19.08,0,0,1-25.71,0,17.28,17.28,0,0,1,0-24.71,18.9,18.9,0,0,1,25.69,0A16.56,16.56,0,0,1,206.58,116.55Zm-9,0a9,9,0,1,0-18.08,0,8.64,8.64,0,0,0,2.56,6.38,8.81,8.81,0,0,0,6.5,2.55,8.74,8.74,0,0,0,9-8.93Z"
                    fill="#fff"
                  />
                  <path
                    d="M244.21,133.16h-8.65l-15.42-20.67v20.67h-8.67V99.85h8.72l15.37,20.71V99.85h8.65Z"
                    fill="#fff"
                  />
                  <path
                    d="M68,155.35H61.22V181H52.55V155.35H45.76v-7.64H68Z"
                    fill="#fff"
                  />
                  <path
                    d="M101,181H92.37V167.68h-12V181H71.73v-33.3H80.4v12.9h12v-12.9H101Z"
                    fill="#fff"
                  />
                  <path
                    d="M126.25,181h-19v-33.3h19V155H115.88v5.57h9.94v7.07h-9.94v6h10.37Z"
                    fill="#fff"
                  />
                  <path
                    d="M163.14,181h-9.28l-1.81-5.91H139.33L137.47,181H128.2L140,147.71H151.3Zm-13-12.31-4.43-14.15-4.46,14.15Z"
                    fill="#fff"
                  />
                  <path
                    d="M183.72,155.35H177V181h-8.67V155.35H161.5v-7.64h22.22Z"
                    fill="#fff"
                  />
                  <path
                    d="M214,181h-10.2l-7.64-12.94V181h-8.67v-33.3h10.16q8.12,0,11.39,2.53c2.18,1.69,3.28,4.37,3.28,8a9.87,9.87,0,0,1-1.9,6.26,9.12,9.12,0,0,1-5.52,3.2ZM203.3,159.36a4.42,4.42,0,0,0-1.24-3.53,6.43,6.43,0,0,0-4.19-1.07h-1.73v8.85h1.58a7,7,0,0,0,4.22-1A3.77,3.77,0,0,0,203.3,159.36Z"
                    fill="#fff"
                  />
                  <path
                    d="M236.57,181h-19v-33.3h19V155H226.19v5.57h9.94v7.07h-9.94v6h10.38Z"
                    fill="#fff"
                  />
                  <path
                    d="M104.65,219.61a2.54,2.54,0,0,1-2.56,2.56,2.4,2.4,0,0,1-1.79-.76,2.46,2.46,0,0,1-.76-1.8,2.56,2.56,0,1,1,5.11,0Z"
                    fill="#fff"
                  />
                  <path
                    d="M128.08,220.66a11.92,11.92,0,0,1-3,1.14,15.72,15.72,0,0,1-3.5.37,11.1,11.1,0,0,1-8.08-3,11.39,11.39,0,0,1,.09-15.78,11.55,11.55,0,0,1,8.34-3.08,13.85,13.85,0,0,1,3.21.35,10.8,10.8,0,0,1,2.72,1v3.78a9.89,9.89,0,0,0-2.77-1.34,10.19,10.19,0,0,0-3-.48,8.2,8.2,0,0,0-5.89,2.12,7.41,7.41,0,0,0-2.24,5.65,6.84,6.84,0,0,0,2.26,5.34,8.41,8.41,0,0,0,5.87,2,11.07,11.07,0,0,0,3.32-.49,8.75,8.75,0,0,0,2.75-1.37Z"
                    fill="#fff"
                  />
                  <path
                    d="M153.33,211.13a11,11,0,0,1-11,11,10.49,10.49,0,0,1-7.75-3.21,10.67,10.67,0,0,1-3.19-7.83,10.49,10.49,0,0,1,3.15-7.74,10.61,10.61,0,0,1,7.79-3.13,10.72,10.72,0,0,1,7.86,3.14A10.43,10.43,0,0,1,153.33,211.13Zm-3.51,0a7.48,7.48,0,1,0-14.95,0,7.48,7.48,0,0,0,2.17,5.48,7.52,7.52,0,0,0,10.63,0A7.54,7.54,0,0,0,149.82,211.13Z"
                    fill="#fff"
                  />
                  <path
                    d="M185.43,221.51h-3.54V209.15a7.83,7.83,0,0,0-1-4.35,3.35,3.35,0,0,0-3-1.41,3.68,3.68,0,0,0-3.47,1.81c-.69,1.21-1,3.24-1,6.11v10.2h-3.52V209.15a8.6,8.6,0,0,0-.85-4.44,3.19,3.19,0,0,0-2.9-1.32,3.93,3.93,0,0,0-3.63,2,12.77,12.77,0,0,0-1.16,6.18v9.95h-3.51V200.89h3.51v2.73a5.55,5.55,0,0,1,2.26-2.53,7.41,7.41,0,0,1,3.67-.83,6.27,6.27,0,0,1,3.47.93,5.1,5.1,0,0,1,2.05,2.69,5.9,5.9,0,0,1,2.43-2.69,7.57,7.57,0,0,1,3.85-.93,5.75,5.75,0,0,1,4.65,2,8.63,8.63,0,0,1,1.67,5.68Z"
                    fill="#fff"
                  />
                  <path
                    d="M510.7,65.24H457.94V265.85H390.17V65.24h-53.1V5.56H510.7Z"
                    fill="#fff"
                  />
                  <path
                    d="M787.69,265.85h-86L615.4,144.3V265.85H547.63V5.56H615.4V122.89L702.72,5.56H782.8L682,131.49Z"
                    fill="#fff"
                  />
                  <path
                    d="M974.65,65.24H921.89V265.85H854.12V65.24H801V5.56H974.65Z"
                    fill="#fff"
                  />
                  <path
                    d="M1182.35,183.75q0,40-27.65,63.81T1081,271.41q-27,0-48.71-9t-43-29.08L1021,184.43q14.32,16.17,29.33,23.93a69.13,69.13,0,0,0,32.2,7.76q14.16,0,23.1-7.42a23.82,23.82,0,0,0,8.93-19.22q0-12.81-8.17-20t-35.32-14.08q-36.25-9.44-53.19-27.56T1001,80.41q0-36.07,24.86-58.24T1091.15,0A141,141,0,0,1,1132,5.73a127.67,127.67,0,0,1,35.9,17.37L1145.6,75.35a88.41,88.41,0,0,0-24.19-14.91A67.29,67.29,0,0,0,1096,55.29q-13.49,0-21.5,5.31t-8,14.25q0,17.37,38.26,27.31c1.69.45,3,.79,3.88,1q39.28,10.45,56.48,29.5T1182.35,183.75Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
            <h3 className="lh-title mb4">
              Our great prices are also now available to buy online
              <br />
              officiallondontheatre.com
            </h3>
            <h3>Follow us on social for the latest news and offers</h3>
            <div className="white-90 lh-copy f2 mt4">
              <span className="db flex items-center w-100 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1017.78"
                  className="w3 h3 mr4"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Ebene_1" data-name="Ebene 1">
                      <path
                        d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>

                <span>OfficialLondonTheatre</span>
              </span>
              <span className="db flex items-center w-100 mt4">
                <svg
                  version="1.1"
                  id="White"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 400 400"
                  className="w3 h3 mr4"
                >
                  <path
                    className="st0"
                    fill="#ffffff"
                    d="M400,200c0,110.5-89.5,200-200,200S0,310.5,0,200S89.5,0,200,0S400,89.5,400,200z M163.4,305.5
	c88.7,0,137.2-73.5,137.2-137.2c0-2.1,0-4.2-0.1-6.2c9.4-6.8,17.6-15.3,24.1-25c-8.6,3.8-17.9,6.4-27.7,7.6
	c10-6,17.6-15.4,21.2-26.7c-9.3,5.5-19.6,9.5-30.6,11.7c-8.8-9.4-21.3-15.2-35.2-15.2c-26.6,0-48.2,21.6-48.2,48.2
	c0,3.8,0.4,7.5,1.3,11c-40.1-2-75.6-21.2-99.4-50.4c-4.1,7.1-6.5,15.4-6.5,24.2c0,16.7,8.5,31.5,21.5,40.1c-7.9-0.2-15.3-2.4-21.8-6
	c0,0.2,0,0.4,0,0.6c0,23.4,16.6,42.8,38.7,47.3c-4,1.1-8.3,1.7-12.7,1.7c-3.1,0-6.1-0.3-9.1-0.9c6.1,19.2,23.9,33.1,45,33.5
	c-16.5,12.9-37.3,20.6-59.9,20.6c-3.9,0-7.7-0.2-11.5-0.7C110.8,297.5,136.2,305.5,163.4,305.5"
                  />
                </svg>

                <span>london_theatre</span>
              </span>
              <span className="db flex items-center w-100 mt4">
                <span className="br-100 w3 h3 bg-white mr4 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 503.84 503.84"
                    className="w2:5 h2:5"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Logo">
                        <path d="M251.92,45.39c67.27,0,75.23.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67a78,78,0,0,1,29,18.85,78,78,0,0,1,18.85,29c3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.57,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78a83.51,83.51,0,0,1-47.81,47.81c-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78,78,0,0,1-29-18.85,78,78,0,0,1-18.85-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78a78,78,0,0,1,18.85-29,78,78,0,0,1,29-18.85c8.88-3.45,22.22-7.55,46.78-8.67,26.57-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52S102.93,7,86.9,13.23A123.52,123.52,0,0,0,42.28,42.28a123.52,123.52,0,0,0-29,44.62C7,102.93,2.74,121.24,1.52,148.05S0,183.5,0,251.92s.29,77,1.52,103.87S7,400.91,13.23,416.94a123.52,123.52,0,0,0,29.05,44.62A123.68,123.68,0,0,0,86.9,490.62c16,6.22,34.34,10.48,61.15,11.71s35.45,1.51,103.87,1.51,77-.29,103.87-1.51,45.12-5.49,61.15-11.71a128.85,128.85,0,0,0,73.68-73.68c6.22-16,10.48-34.34,11.71-61.15s1.51-35.45,1.51-103.87-.29-77-1.51-103.87-5.49-45.12-11.71-61.15a123.68,123.68,0,0,0-29.06-44.62,123.52,123.52,0,0,0-44.62-29C400.91,7,382.6,2.74,355.79,1.52S320.34,0,251.92,0Z" />
                        <path d="M251.92,122.56A129.37,129.37,0,1,0,381.29,251.92,129.35,129.35,0,0,0,251.92,122.56Zm0,213.33a84,84,0,1,1,84-84A84,84,0,0,1,251.92,335.89Z" />
                        <circle cx="386.4" cy="117.44" r="30.23" />
                      </g>
                    </g>
                  </svg>
                </span>

                <span>londontheatre</span>
              </span>
            </div>
          </div>
        </div>
      );
    }

    const viewContainer = useRef<HTMLDivElement>(null);
    const viewport = useRef<HTMLDivElement>(null);

    const showsList = useRef<HTMLDivElement>(null);

    const scalingEnabled = false;
    const windowSize = useWindowSize();

    let scaleStyle: CSSProperties = {};
    const targetWidth = 1080;
    const targetHeight = 1920;
    if (scalingEnabled && windowSize && windowSize.height !== targetHeight) {
      const scaleFactor = windowSize.height / targetHeight;
      scaleStyle = {
        transformOrigin: "left top",
        transform: `scale(${scaleFactor})`,
        height: targetHeight,
        width: targetWidth,
      };
    }

    if (windowSize.width !== windowWidth) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    return (
      <div
        className="bg-black flex flex-column vh-100 overflow-hidden"
        style={scaleStyle}
      >
        <BoardControlsUI displayDate={date} />

        <div
          className="flex-auto flex flex-row overflow-hidden"
          ref={viewContainer}
        >
          <ShowsList
            viewportRef={viewport}
            showsRef={showsList}
            pageState={boardState.page}
            date={date}
            shows={shows}
            boardState={boardState}
            initiateBoard={initiateBoard}
          />
        </div>
      </div>
    );
  }
);